
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { default as __Next_Translate_old_getStaticProps__19503d9749f__ } from '@utils/getBasicStaticProps';
import Page404 from '@screens/404';
export {} from '@utils/getBasicStaticProps';
export default Page404;

    async function __Next_Translate__getStaticProps__19503d9749f__(ctx) {
      const res = await __Next_Translate_old_getStaticProps__19503d9749f__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19503d9749f__ as getStaticProps }
  